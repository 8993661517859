import React from "react";
import "components/style.css";

import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import { Link } from "react-router-dom";
import { postFetch } from "lib/fetch";
import { useState } from "react";
import { blueGradient1 } from "components/tailwindClasses";
import { Cookie } from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";

function Index() {
  let navigate = useNavigate();
  let email = useRef("");
  let password = useRef("");
  let token = useRef("");
  let capchaRef = useRef(null);

  let [step, setStep] = useState(0);

  let loginCall = async () => {
    let requestData = {
      email: email.current.value,
      password: password.current.value,
    };

    const response = await postFetch(`/loginV2/CompanyEngineer`, requestData);

    if (response) {
      if (response?.isAuth == true) {
        // temp_auth cookie gets set here by backend

        // use this whenever reloading
        if (response?.two_fa_enabled) {
          window.localStorage.setItem(
            "temp_auth_token",
            response?.temporary_auth_token
          );
          setStep(() => 1);
        } else {
          axios.defaults.headers.common["Authorization"] =
            response?.bearer_token;
          window.localStorage.setItem("bearer_token", response?.bearer_token);
          toast.success("Login Success !");

          navigate("/dashboard");
        }
      } else if (response?.isAuth == false) {
        toast.error("Email or password is wrong !");
      } else if (response?.error == true) {
        toast.error(response?.message);
      } else toast.warning(response?.error?.message);
    } else {
      toast.error("could not login, try later");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    try {
      const currentStepForm = document.getElementById(`loginForm3424`);

      if (currentStepForm && currentStepForm.checkValidity()) {
        if (!capchaRef.current.getValue()) {
          return toast.warn("please verify captcha");
        }
        loginCall();
      } else currentStepForm.reportValidity();
    } catch (e) {
      console.log(e);
      toast("Error ! Try Later");
    }
  };
  const handleForm2Submit = async (e) => {
    e.preventDefault();

    try {
      const currentStepForm = document.getElementById(`loginForm45345`);

      if (currentStepForm && currentStepForm.checkValidity()) {
        const tempAuthToken = localStorage.getItem("temp_auth_token");

        const res = await postFetch(
          "/secureloginV2",
          {
            twofatoken: token.current.value,
            temporary_auth: tempAuthToken,
          },
          {},
          {
            temporary_auth: tempAuthToken,
          }
        );

        if (res?.isAuth) {
          axios.defaults.headers.common["Authorization"] = res?.bearer_token;
          window.localStorage.setItem("bearer_token", res?.bearer_token);

          navigate("/dashboard");
        } else if (res?.error && res?.message) {
          toast.error(res?.message);
        } else {
          toast.error("Some error occured, try later");
        }
      } else currentStepForm.reportValidity();
    } catch (e) {
      console.log(e);
      toast("Error ! Try Later");
    }
  };

  const handleForgotClick = (e) => {
    e.preventDefault();

    window.localStorage.setItem("email", `${email.current.value}`);

    navigate("/reset-pass");
  };

  const handleCaptchaChange = () => {
    // toast(capchaRef.current?.getValue());
  };

  return (
    <div className="flex h-full">
      <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
        <div className="absolute top-1/3 left-[45%] -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl w-fit mb-2">
            {step == 1 && (
              <b>
                Two Factor <br />
                Authentication
              </b>
            )}
            <div className="text-2xl text-[#c3d2d2] w-fit ">
              {step == 1 && (
                <div className="">
                  Your account is secured using two factor authentication. To
                  finish signing in view your TFA device and type the
                  authentication below
                </div>
              )}
            </div>
          </div>
        </div>
        <img
          src={"/loginBgLines.svg"}
          className="  absolute top-0 left-0 w-1/3  -scale-y-100"
          alt="anim"
        />
        <img
          src="/NSELogo.svg"
          alt=""
          className="absolute top-0 right-0 w-40 mt-[4%] mr-[4%]"
        />

        <img
          src="/engineerLoginLogo.svg"
          alt=""
          className="absolute left-0 bottom-0 mb-2p ml-3p w-1/2"
        />

        <div className="absolute right-0 bottom-0 flex mr-[7%] mb-2p">
          <img src="/loginLine.svg" alt="" className="mr-4 " />
          <div className="text-3xl font-bold text-white">
            Engineer <br /> Portal
          </div>
        </div>
      </div>

      <div className="relative w-[42%] bg-[#ececec] h-full flex flex-col ">
        <div className="h-[10%] p-10 ">
          <div className="text-xl font-medium">
            <img src={"/cyteHackLogo.svg"} className=" h-10 inline mr-2" />
            CyeNet
          </div>
        </div>
        {step == 0 && (
          <div className="  w-full p-10">
            <form
              onSubmit={handleFormSubmit}
              id="loginForm3424"
              className="w-full"
            >
              <h1 className="mb-10 text-center text-3xl">
                <b>Log In</b>
              </h1>
              <label htmlFor="email">
                <p className="font-medium text-base">Email</p>
              </label>
              <input
                ref={email}
                type="email"
                className="text-base mt-1p mb-3p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
                defaultValue={""}
                required
              />
              <label htmlFor="pass">
                <p className="font-medium text-base">Password</p>
              </label>
              <input
                ref={password}
                type="password"
                className="text-base mt-1p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
                defaultValue={""}
                required
              />
              <Link
                onClick={handleForgotClick}
                className="underline text-black"
              >
                <div className="py-2">Forgot Password?</div>
              </Link>

              <div className="mt-1p mb-2p">
                <ReCAPTCHA
                  ref={capchaRef}
                  sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY || null}
                  onChange={handleCaptchaChange}
                />
              </div>

              <button className="w-full">
                <button
                  type="submit"
                  onClick={handleFormSubmit}
                  className={
                    blueGradient1 + " w-full h-15 rounded-10 font-bold"
                  }
                >
                  Log In
                </button>
              </button>
            </form>
            <div
              className=" text-center text-sm font-bold text-blue1 cursor-pointer absolute bottom-0 left-1/2 -translate-x-1/2 mb-10"
              onClick={() => {
                navigate("/login");
              }}
            >
              Change User
            </div>
          </div>
        )}
        {step == 1 && (
          <div className="flex justify-center items-center w-full p-10">
            <form
              onSubmit={handleForm2Submit}
              id="loginForm45345"
              className="w-full"
            >
              <h1 className="mb-10 text-center text-3xl">
                <b>2Fa Login</b>
              </h1>

              <label htmlFor="pass">
                <p className="font-medium text-base">Token</p>
              </label>
              <input
                ref={token}
                className="text-base mt-1p mb-2p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
                defaultValue={""}
                required
              />
              <button className="w-full">
                <button
                  type="submit"
                  onClick={handleForm2Submit}
                  className={
                    blueGradient1 + " w-full h-15 rounded-10 font-bold"
                  }
                >
                  Verify 2FA
                </button>
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
